import React from 'react'
// import Header from './Header'

function Timeline() {
    function AddCC(urlOfTheLibrary) {
        const script = document.createElement('link');
        script.href = urlOfTheLibrary;
        script.rel = "stylesheet";
        script.type = "text/css";
        document.head.appendChild(script);
    }
    return (  
        <React.Fragment>
            {/* <Header /> */}
            {AddCC('/css/timline.css')}
            {AddCC('https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css')}

            <div class="container-fluid" style={{ height: 100 + '%' }}>
                <div class="row" style={{ height: 100 + '%' }} id="section_main_div">
                    <div class="col-lg-4" style={{ color: '#ffffff' }} id="section_main_div1">
                        <a class="text-white navbar-brand navbarbrandmain" href="/">
                            <img src='/Images/mytiffi white png-01.png' style={{ height: 50, width: 140,margin:20 }} />
                        </a>
                        <hr />
                        <h3 style={{ marginLeft:40,marginRight:40,marginTop:60, }}>Few click away from Delicious food, delivered right to your doorstep</h3>
                    </div>
                    <div class="col-lg-8" id="section_main_div2" style={{ paddingLeft: 30, backgroundColor: '#eee', paddingTop:20,}}>
                       <div id='section_main_div2_div1'>
                       
                        <div class="container bootstrap snippets bootdeys">
                            <div class="col-md-12">
                                <div class="timeline-centered timeline-sm">
                                    <article class="timeline-entry">
                                        <div class="timeline-entry-inner">
                                            <div class="timeline-icon bg-violet">
                                                <i class="fa fa-check"></i>
                                            </div>
                                            <div>

                                                <p class="mt-3"><b class="ml-3" style={{marginLeft:10,}}>Select Tiffin quantity</b></p>
                                                <div class="d-flex justify-content-start">
                                                    <i class="fa fa-plus-circle mt-1" style={{ marginLeft: 40 + 'px',marginRight:10, fontSize: 25 + 'px', color: 'red' }}
                                                        onclick="increment()"></i>

                                                    <input id="demoInput" class="form-control ml-2" type="number" value="1" style={{ width: 100 + 'px',textAlign:'center' }} />
                                                    <i class="fa fa-minus-circle mx-2 mt-1" style={{ fontSize: 25 + 'px', color: 'red' }}
                                                        onclick="decrement()"></i>

                                                </div>
                                            </div>
                                        </div>
                                    </article>


                                    <article class="timeline-entry">
                                        <div class="timeline-entry-inner">
                                            <div class="timeline-icon bg-violet"><i class="fa fa-check"></i></div>
                                            <p class="mt-3"><b class="ml-2" style={{marginLeft:10,}}>Choose Meal type</b></p>

                                            <div class="container-fluid" id="container_main_sec">

                                                <div class="row" id='container_main_sec_div1'>
                                                    <div class="col-md-3">
                                                        <div class="button" id="choose_meal_type_btn">
                                                            <input type="radio" id="a21" name="check-substitution-1" />
                                                            <label class="btn btn-default btn btn-light" for="a21" id="choose_btn1">

                                                                <img src="/Images/1.png" alt="" srcset="" id="noncheckicon" class="choose_meal_type_img" />
                                                                <span class="choose_meal_type_span"><b>Veg</b>

                                                                </span>
                                                                <i class="fa fa-check" id="checkcircle"></i>

                                                            </label>


                                                        </div>
                                                    </div>

                                                    <div class="col-md-3">
                                                        <div class="button" id="choose_meal_type_btn">
                                                            <input type="radio" id="a22" name="check-substitution-1" />
                                                            <label class="btn btn-default btn btn-light" for="a22" id="choose_btn1">

                                                                <img src="/Images/2.png" alt="" srcset="" id="noncheckicon" class="choose_meal_type_img" />
                                                                <span id="choose_meal_type_span"><b>Nonveg</b>

                                                                </span>
                                                                <i class="fa fa-check" id="checkcircle"></i>

                                                            </label>


                                                        </div>
                                                    </div>

                                                    <div class="col-md-3">
                                                        <div class="button" id="choose_meal_type_btn">
                                                            <input type="radio" id="a23" name="check-substitution-1" />
                                                            <label class="btn btn-default btn btn-light" for="a23" id="choose_btn1">

                                                                <img src="/Images/3.png" alt="" srcset="" id="noncheckicon" class="choose_meal_type_img" />
                                                                <span id="choose_meal_type_span"><b>Egg</b>

                                                                </span>
                                                                <i class="fa fa-check" id="checkcircle"></i>

                                                            </label>


                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">

                                                        <div class="button" id="choose_meal_type_btn">
                                                            <input type="radio" id="a24" name="check-substitution-1" />
                                                            <label class="btn btn-default btn btn-light" for="a24" id="choose_btn1">
                                                                <img src="/Images/3.png" alt="" srcset="" id="noncheckicon" class="choose_meal_type_img" />

                                                                <span id="choose_meal_type_span"><b>Alternate</b>

                                                                </span>
                                                                <i class="fa fa-check" id="checkcircle"></i>

                                                            </label>


                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </article>






                                    <article class="timeline-entry">
                                        <div class="timeline-entry-inner">
                                            <div class="timeline-icon bg-violet"><i class="fa fa-check"></i></div>
                                            <p class="mt-3"><b class="ml-2" style={{marginLeft:10,}}>When</b></p>

                                            <div class="container-fluid " id="container_main_sec">

                                                <div class="row">
                                                    <div class="col-md-2">


                                                        <div class="button" id="when_button_new1">
                                                            <input type="radio" id="a31" name="check-substitution-2" />
                                                            <label class="btn btn-default btn btn-light" for="a31" id="choose_btn2">
                                                                <i class="fa fa-calendar text-center ml-3 mt-2" id="noncheckicon" style={{marginLeft:20}}></i> <br /><br />
                                                                <span style={{ fontSize: 14 + 'px' }}><b>Today</b>

                                                                </span>
                                                                <i class="fa fa-check" id="checkcircle" style={{ marginLeft: 70 + 'px', marginTop: -90 + 'px' }}></i>

                                                            </label>


                                                        </div>


                                                    </div>
                                                    <div class="col-md-2">


                                                        <div class="button" id="when_button_new1">
                                                            <input type="radio" id="a32" name="check-substitution-2" />
                                                            <label class="btn btn-default btn btn-light" for="a32" id="choose_btn2">
                                                                <i class="fa fa-calendar text-center ml-3 mt-2" id="noncheckicon" style={{marginLeft:20}}></i> <br /><br />
                                                                <span style={{ fontSize: 14 + 'px' }}><b>Tommorrow</b>

                                                                </span>
                                                                <i class="fa fa-check" id="checkcircle" style={{ marginLeft: 70 + 'px', marginTop: -90 + 'px' }}></i>

                                                            </label>


                                                        </div>


                                                    </div>


                                                    <div class="col-md-2">


                                                        <div class="button" id="when_button_new1">
                                                            <input type="radio" id="a33" name="check-substitution-2" />
                                                            <label class="btn btn-default btn btn-light" for="a33" id="choose_btn2">
                                                                <i class="fa fa-calendar text-center ml-3 mt-2" id="noncheckicon" style={{marginLeft:20}}></i> <br /><br />
                                                                <span style={{ fontSize: 14 + 'px' }}><b>Weekly</b>

                                                                </span>
                                                                <i class="fa fa-check" id="checkcircle" style={{ marginLeft: 70 + 'px', marginTop: -90 + 'px' }}></i>

                                                            </label>


                                                        </div>


                                                    </div>


                                                    <div class="col-md-2">


                                                        <div class="button" id="when_button_new1">
                                                            <input type="radio" id="a34" name="check-substitution-2" />
                                                            <label class="btn btn-default btn btn-light" for="a34" id="choose_btn2">
                                                                <i class="fa fa-calendar text-center ml-3 mt-2" id="noncheckicon" style={{marginLeft:20}}></i> <br /><br />
                                                                <span style={{ fontSize: 14 + 'px' }}><b>Monthly</b>

                                                                </span>
                                                                <i class="fa fa-check" id="checkcircle" style={{ marginLeft: 70 + 'px', marginTop: -90 + 'px' }}></i>

                                                            </label>


                                                        </div>


                                                    </div>


                                                    <div class="col-md-2">


                                                        <div class="button" id="when_button_new1">
                                                            <input type="radio" id="a35" name="check-substitution-2" />
                                                            <label class="btn btn-default btn btn-light" for="a35" id="choose_btn2">
                                                                <i class="fa fa-calendar text-center ml-3 mt-2" id="noncheckicon" style={{marginLeft:20}}></i> <br /><br />
                                                                <span style={{ fontSize: 14 + 'px' }}><b>Quarterly</b>

                                                                </span>
                                                                <i class="fa fa-check" id="checkcircle" style={{ marginLeft: 70 + 'px', marginTop: -90 + 'px' }}></i>

                                                            </label>


                                                        </div>


                                                    </div>


                                                    <div class="col-md-2">


                                                        <div class="button" id="when_button_new1">
                                                            <input type="radio" id="a36" name="check-substitution-2" />
                                                            <label class="btn btn-default btn btn-light" for="a36" id="choose_btn2">
                                                                <i class="fa fa-calendar text-center ml-3 mt-2" id="noncheckicon" style={{marginLeft:20}}></i> <br /><br />
                                                                <span style={{ fontSize: 14 + 'px' }}><b>Alternate</b>

                                                                </span>
                                                                <i class="fa fa-check" id="checkcircle" style={{ marginLeft: 70 + 'px', marginTop: -90 + 'px' }}></i>

                                                            </label>


                                                        </div>


                                                    </div>





                                                </div>

                                            </div>

                                        </div>
                                    </article>






                                    <article class="timeline-entry">

                                    </article>



                                    <article class="timeline-entry">
                                        <div class="timeline-entry-inner">
                                            <div class="timeline-icon bg-violet"><i class="fa fa-check"></i></div>
                                            <p class="mt-3"><b class="ml-2" style={{marginLeft:10,}}>In</b></p>





                                        </div>

                                    </article>




                                    <div class="d-flex" id="Insectionactivediv1">





                                    </div>
                                </div>
                            </div>




                            <div class="container-fluid" id="container_main_sec">

                                <div class="row" id="container_main_sec_when_last">
                                    <div class="col-md-3">
                                        <div class="button" id="choose_meal_type_btn">
                                            <input type="radio" id="a41" name="check-substitution-3" />
                                            <label class="btn btn-default btn btn-light" for="a41" style={{ width: 200 + 'px' }} id="choose_btn1">
                                                <img src="/Images/icons8-meal-50.png" alt="" srcset="" id="noncheckicon"
                                                    style={{ width: 45 + 'px', height: 45 + 'px', borderRadius: 5 + 'px' }} />

                                                <span style={{ float: 'left', marginTop: 5 + 'px' }}>&nbsp;&nbsp;&nbsp;&nbsp;<b>Lunch</b>

                                                </span>
                                                <i class="fa fa-check" id="checkcircle"></i>

                                            </label>


                                        </div>
                                    </div>

                                    <div class="col-md-3">
                                        <div class="button" id="choose_meal_type_btn">
                                            <input type="radio" id="a42" name="check-substitution-3" />
                                            <label class="btn btn-default btn btn-light" for="a42" style={{ width: 200 + 'px' }} id="choose_btn1">
                                                <img src="/Images/6 (2).png" alt="" srcset="" id="noncheckicon"
                                                    style={{ width: 45 + 'px', height: 45 + 'px', borderRadius: 5 + 'px' }} />
                                                <span style={{ float: 'left', marginTop: 5 + 'px' }}>&nbsp;&nbsp;&nbsp;&nbsp;<b>Dinner</b>

                                                </span>
                                                <i class="fa fa-check" id="checkcircle"></i>

                                            </label>


                                        </div>
                                    </div>

                                    <div class="col-md-3">
                                        <div class="button" id="choose_meal_type_btn">
                                            <input type="radio" id="a43" name="check-substitution-3" />
                                            <label class="btn btn-default btn btn-light" for="a43" style={{ width: 200 + 'px' }} id="choose_btn1">
                                                <img src="/Images/8.png" alt="" srcset="" id="noncheckicon"
                                                    style={{ width: 45 + 'px', height: 45 + 'px', borderRadius: 5 + 'px' }} />
                                                <span style={{ float: 'left', marginTop: 5 + 'px' }}>&nbsp;&nbsp;&nbsp;&nbsp;<b>Both</b>

                                                </span>
                                                <i class="fa fa-check" id="checkcircle"></i>

                                            </label>


                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>
                        </div>
                        <a href="#" style={{ textAlign: 'center', color: 'white',backgroundColor:'red',  fontWeight: 700,marginTop:40,marginRight:25+'%',float:'right' }}>

                            Next  <img src='/Images/icons8-right-arrow-24 white.png' /><br /></a>
                    </div>
                </div>
            </div>
        </React.Fragment >
    )
}

export default Timeline;