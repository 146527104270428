import Dailycss from './Dailycss';
import Menucardjs from './Menucardjs';
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios'

function Menucard() {



 const id = useParams();

const [quantity,setQuantity]=useState(1);
const [meal,setMeal]=useState('');
const [duration,setDuration]=useState('');
const [delivery,setDelivery]=useState('');

//const [uid,setUid]=useState(uid);
const uid=localStorage.getItem('username');
console.log(uid);
function handleSubmit(){
  const data={
    'quantity':quantity,
    'meal_type':meal,
    'duration':duration,
    'delivery_time':delivery,
    'uid':uid
  }
  //console.log(data);

    axios.post("http://localhost:8000/api/subscriptionplan",data)
    .then((resp)=>{
           const data = resp.data;
           console.log(resp);
           if(data[0].status === "success")
           {
               console.log("Data Added");
               alert('Data Added!!!');
               window.location.href='/Subscription';
           }
           else
           {
               console.log("Error");
           }
       });
  }






function logintest()
{

    const uidd=localStorage.getItem('username');
    console.log(uidd);
    // if(uidd==null)
    // {
    //   window.location.href='/';
    // }

}

function Logout()
  {
    console.log('test');
      localStorage.removeItem('username');
      window.location.href='/';

  }
  useEffect(() => {
    logintest();
  },[])



  return (

    <div>
<Dailycss/>
<Menucardjs/>



    <div className="container-fluid-md menucard">
        <div className="row gx-0">
            <div className="col-md-3 c1">
<div id="mySidepanel" className="sidepanel">
  <a href="javascript:void(0)" className="closebtn" onclick="closeNav()">×</a>
    <a href="#">Home</a>
  <a href="#">Menu</a>
  <a href="#">Specilities</a>
  <a href="#">About</a>
  <a href="#">Contact</a>
  <a href="#">Login</a>
</div>

<button className="openbtn" onclick="openNav()">☰ <img src="images/3.png" height="50px" /></button>
<hr/>
<h3>Few clicks away from <br/>Delicious food,delivered<br/> right to your to doorstep!</h3>
            </div>
            <div className="col-md-9 c2">
                <div className="line">
                <input type="hidden" name="uid" value={uid} />
                <button onClick={()=>Logout()}>Logout</button>

                    <ul>
                    <li><i className="fas fa-check"></i><h4>Select Tiffin Quantity</h4>
                        <div className="input-group">
                            <input type="button" value={quantity} name="quantity" onChange={(quantity) => {setQuantity(quantity.target.value)  }} className="button-plus" data-field="quantity"
                             style={{borderRadius: "50%"}}  />
                            <input type="number" step="1" max=""  name="quantity" className="quantity-field"style={{borderRadius: "8px"}}
                            value={quantity} onChange={(quantity) => {setQuantity(quantity.target.value)  }}
                            />
                            <input type="button" value="-" className="button-minus"
                          data-field="quantity" style={{borderRadius: "50%"}}/>
                          </div>
                    </li>
                    <li className=""><i className="fas fa-check"></i><h4>Choose Meal Type</h4>
                      <li><div className="radio-buttons">
                        <label className="custom-radio">
                          <input type="radio" name="radio1 meal_type" value='veg' onChange={(meal) => {setMeal(meal.target.value)  }}/>
                          <span className="radio-btn"><i className="fa fa-check"></i>
                          <div className="hobbies-icon">
                            <div className="veg1">
                              <i className="fa-solid fa-broccoli"></i>
                          </div>
                               <h3>Veg</h3>
                          </div>
                          </span>
                        </label>
                        <label className="custom-radio">
                          <input type="radio" name="radio1 meal_type" value='nonveg' onChange={(meal) => {setMeal(meal.target.value)  }}/>
                          <span className="radio-btn nonvegbtn"><i className="fa fa-check"></i>
                          <div className="hobbies-icon">
                            <div className="Nonveg">
<i className="fa-solid fa-turkey"></i>                            </div>
                            <h3>Nonveg</h3>
                          </div>
                          </span>
                        </label>
                        <label className="custom-radio">
                          <input type="radio" name="radio1 meal_type" value='egg' onChange={(meal) => {setMeal(meal.target.value)  }}/>
                          <span className="radio-btn egg"><i className="fa fa-check"></i>
                          <div className="hobbies-icon">
                            <div className="Egg">
                            <i className="fa-solid fa-egg"></i>
                            </div>
                              <h3>Egg</h3>
                          </div>
                          </span>
                        </label>
                        <label className="custom-radio">
                          <input type="radio" name="radio1" value='Alternate' onChange={(meal) => {setMeal(meal.target.value)  }}/>
                          <span className="radio-btn alternate"><i className="fa fa-check"></i>
                          <div className="hobbies-icon">
                            <div className="Alternate">
                                <i className="fa fa-exchange-alt"></i>
                     </div>
                            <h3>Alternate</h3>
                          </div>
                          </span>
                        </label>

                      </div>
                    </li>
                    </li>
                        <li className=""><i className="fas fa-check"></i><h4>When</h4>
                        <li><div className="radio-buttons1">
                          <label className="custom-radio1">
                            <input type="radio" name="radio2 duration" value='today' onChange={(duration) => {setDuration(duration.target.value)  }}/>
                            <span className="radio-btn1"><i className="fa fa-check"></i>
                            <div className="hobbies-icon1">
                              <i className="fa fa-calendar"></i>
                              <h3>Today</h3>
                            </div>
                            </span>
                          </label>
                          <label className="custom-radio1">
                            <input type="radio" name="radio2 duration"
                            value='tomorrow' onChange={(duration) => {setDuration(duration.target.value)  }}/>
                            <span className="radio-btn1"><i className="fa fa-check"></i>
                            <div className="hobbies-icon1">
                              <i className="fa fa-calendar"></i>
                              <h3>Tomorrow</h3>
                            </div>
                            </span>
                          </label>
                          <label className="custom-radio1">
                            <input type="radio" name="radio2"
                            value='weekly' onChange={(duration) => {setDuration(duration.target.value)  }}/>
                            <span className="radio-btn1"><i className="fa fa-check"></i>
                            <div className="hobbies-icon1">
                              <i className="fa fa-calendar"></i>
                              <h3>Weekly</h3>
                            </div>
                            </span>
                          </label>
                          <label className="custom-radio1">
                            <input type="radio" name="radio2"
                            value='monthly' onChange={(duration) => {setDuration(duration.target.value)  }}
                            />
                            <span className="radio-btn1"><i className="fa fa-check"></i>
                            <div className="hobbies-icon1">
                              <i className="fa fa-calendar"></i>
                              <h3>Monthly</h3>
                            </div>
                            </span>
                          </label>
                            <label className="custom-radio1">
                            <input type="radio" name="radio2"
                            value='quaterly' onChange={(duration) => {setDuration(duration.target.value)  }}
                            />
                            <span className="radio-btn1"><i className="fa fa-check"></i>
                            <div className="hobbies-icon1">
                              <i className="fa fa-calendar"></i>
                              <h3>Quaterly</h3>
                            </div>
                            </span>
                          </label>
                              <label className="custom-radio1">
                            <input type="radio" name="radio2"
                            value='alternate' onChange={(duration) => {setDuration(duration.target.value)  }}
                            />
                            <span className="radio-btn1"><i className="fa fa-check"></i>
                            <div className="hobbies-icon1">
                              <i className="fa fa-calendar"></i>
                              <h3>Alternate</h3>
                            </div>
                            </span>
                          </label>
                        </div></li>
                        </li>
                        <li className=""><i className="fas fa-check"></i><h4>In</h4>
                        <li>
                          <div className="radio-buttons3">
                            <label className="custom-radio3">
                              <input type="radio" name="radio3 delivery_time"
                              value='lunch' onChange={(delivery) => {setDelivery(delivery.target.value)  }}/>
                              <span className="radio-btn3"><i className="fa fa-check"></i>
                              <div className="hobbies-icon3">
                               <i className="fa-solid fa-utensils"></i>
                             <h3>Lunch</h3>
                              </div>
                              </span>
                            </label>
                            <label className="custom-radio3">
                              <input type="radio" name="radio3 delivery_time"
                              value='dinner' onChange={(delivery) => {setDelivery(delivery.target.value)  }}/>

                              <span className="radio-btn3"><i className="fa fa-check"></i>
                              <div className="hobbies-icon3">
<i className="fa fa-bell-concierge"></i>                                <h3>Dinner</h3>
                              </div>
                              </span>
                            </label>
                            <label className="custom-radio3">
                              <input type="radio" name="radio3 delivery_time"
                              value='both' onChange={(delivery) => {setDelivery(delivery.target.value)  }}/>

                              <span className="radio-btn3"><i className="fa fa-check"></i>
                              <div className="hobbies-icon3">
<i className="fa fa-plate-utensils"></i>                                <h3>Both</h3>
                              </div>
                              </span>
                            </label>

                          </div>

                        </li>
                        </li>
                        </ul>

                </div>

  <button className="btn btn-danger bn" onClick={handleSubmit}>Next<i className="fa fa-arrow-right"></i></button>
                </div>


                </div>



                </div>




    </div>
  )
}

export default Menucard;
