import React from "react";
function Dashboard() {
    const unm = localStorage.getItem('uname');
    function addCss(urlOfTheLibrary) {
        const script = document.createElement('link');
        script.href = urlOfTheLibrary;
        script.rel = "stylesheet";
        script.type = "text/css";
        document.head.appendChild(script);
        // let draggableEl = document.getElementById('menucard');
        // new Draggable(draggableEl);
    }
    return (
        <React.Fragment>
            {addCss('/css/sub/PageNo11.css')}
            <div class="container-fluid-md">
                <div class="row gx-0">
                    <div class="col-md-4 t1">


                        <nav class="navbar navbar-dark ms-4" aria-label="First navbar example">
                            <div class="container-fluid">
                                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarsExample01" aria-controls="navbarsExample01" aria-expanded="false" aria-label="Toggle navigation">
                                    <span class="navbar-toggler-icon"></span>
                                    <span><img src="/Images/3.png" height="50px;" /></span>

                                </button>

                                <div class="collapse navbar-collapse" id="navbarsExample01">
                                    <ul class="navbar-nav me-auto mb-2">
                                        <li class="nav-item">
                                            <a class="nav-link active" aria-current="page" href="#">Home</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" href="#">Menu</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Specilities</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">About</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Contact</a></li>
                                        <li class="nav-item">
                                            <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Login</a>
                                        </li>



                                    </ul>
                                </div>
                                <a class="navbar-brand" href="#"></a>

                            </div>

                        </nav>


                        <hr style={{ border: '1px solid lightgray' }} />
                        <div id="main">
                        </div>
                        <div id="list">
                            <ul>
                                <a href="/dashboard" style={{ textDecoration: 'none' }}><li class="active">Overview</li></a>
                                <a href="/profile" style={{ textDecoration: 'none' }}><li>Profile</li></a>
                                <a href="#" style={{ textDecoration: 'none' }}> <li>Invoices</li></a>
                                <a href="#" style={{ textDecoration: 'none' }}><li>Help & Support</li></a>
                                <li onClick={() => {
                                    localStorage.clear();
                                    window.location.href = '/'
                                }} style={{ cursor: 'pointer' }}>Logout</li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-8 t2">
                        <li><i class="fa fa-shopping-cart "></i>
                            <span class="translate-middle px-2 text-light bg-dark rounded-circle" style={{ float: 'right', marginTop: 46, marginRight: -46 }}>0</span>
                        </li>
                        <hr style={{ marginTop: 100, color: 'black' }} />
                        <h4>Hi {unm},</h4>
                        <button id="add">
                            {/* <input type="button" value="+" class="button-plus5" data-field="quantity"
                                    style={{borderRadius: 50+'%'}}/> */}
                            <font style={{ fontSize: 20 }}>+ </font>
                            {/* <i class='fa fa-plus-circle button-plus5' style={{color: 'red'}}></i> */}
                            <span>Add Tiffin</span>
                        </button>

                        <div class="row row-cols-1 row-cols-md-2 g-4 px-4 mt-4">
                            <div class="col-md-8 ">
                                <div class="card " style={{ border: 'none' }}>
                                    <div class="card-body">
                                        <h5 class="card-title">Today's Meal on the way </h5>
                                        <img src="/Images/Scooter.png" style={{ marginLeft: -35, marginTop: -40 }} />
                                        <i class='fas fa-pencil-alt' style={{ float: 'right', marginTop: -30, borderBottom: '3px solid black' }}></i>
                                        <button id="box">
                                            <span id="one2">1</span>
                                            <span><i class="fa-solid fa-battery-full fa-rotate-270" style={{ color: 'green', backgroundColor: 'white', padding: 2, marginTop: 6, borderRadius: 50 + '%', marginLeft: -20 }}></i></span>
                                            <span><i class="fa fa-brightness"></i></span>

                                        </button>
                                        <button id="box1">
                                            <span id="one3">1</span>
                                            <span><i class="fa-solid fa-battery-full fa-rotate-270" style={{ color: 'green', backgroundColor: 'white', padding: 3, marginTop: 5, borderRadius: 50 + '%', marginLeft: -22 }}></i></span>
                                            <span><i class="fa fa-moon-o"></i></span>

                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-4 ">
                                <div class="card" style={{ border: 'none' }}>
                                    <div class="card-body">
                                        <h5 class="card-title">Subscription </h5>
                                        <button id="weekly1">Weekly</button>
                                        <i class='fas fa-pencil-alt' style={{ float: 'right', marginTop: -30, borderBottom: '3px solid black' }}></i>
                                        <i class="fa fa-rupee" style={{ color: 'black', marginTop: 20, float: 'left', fontSize: 25, marginLeft: 10 }}></i>
                                        <h2 id="Rupee1">2000</h2>
                                        <p>Subscription Expire on</p><span class="mt-5">07/04/2024</span>

                                    </div>
                                </div>
                            </div>
                        </div>





                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default Dashboard;