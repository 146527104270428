import React, { useEffect, useState } from "react"

function Loader() {

    function addCss(urlOfTheLibrary) {
        const script = document.createElement('link');
        script.href = urlOfTheLibrary;
        script.rel = "stylesheet";
        script.type = "text/css";
        document.head.appendChild(script);
    }
    function AddScript(path) {
        const script = document.createElement('script')
        script.src = path;
        script.async = true;
        document.body.appendChild(script);
    }
    return (
        <React.Fragment>
            {addCss("PageNo11.css")}
            {addCss('/css/Style.css')}
            {addCss('/css/navbar.css')}
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12 m1">
                        <img src="/Images/Scooter.png" id="img" style={{ marginLeft: 'auto', marginRight: 'auto' }} />
                    </div>
                </div>
            </div>
            {AddScript('https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js')}
            {AddScript('/js/home.js')}
        </React.Fragment>
    )
}
export default Loader;