import React, { useState } from 'react';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';

import { GoogleLoginButton } from "react-social-login-buttons";
import { LoginSocialGoogle } from "reactjs-social-login";

import { LoginSocialFacebook } from 'reactjs-social-login';
import { FacebookLoginButton } from 'react-social-login-buttons'; // 
import { formatIsoTimeString } from '@fullcalendar/core/internal';
import Subscription from '../components/Subscription';
import SubscriptionModal from '../components/subscription/SubscriptionModal';
import Menucard from './Menucard';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin, { Draggable } from '@fullcalendar/interaction'

const Header = () => {

  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const [error, setError] = useState(false);
  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [password, setPassword] = useState('');
  const warn = { borderWidth: 1, borderColor: '#f44336' }
  const nowarn = { borderWidth: 1, borderColor: '#d9dee3' }


  const [profile, setProfile] = useState('');
  const session = localStorage.getItem('username');
  // console.log('test');

  function addCss(urlOfTheLibrary) {
    const script = document.createElement('link');
    script.href = urlOfTheLibrary;
    script.rel = "stylesheet";
    script.type = "text/css";
    document.head.appendChild(script);
  }
  function AddScript(path) {
    const script = document.createElement('script')
    script.src = path;
    script.async = true;
    document.body.appendChild(script);
  }
  function Adddata() {
    const data = {
      'email': email,
      'mobile': mobile,
      'password': password
    };
    if (email == "") {
      toast.error("Enter Email");
      setEmail();
    }
    else if (mobile == "") {
      toast.error("Enter Mobile");
      setMobile();
    }
    else if (password == "") {
      toast.error("Enter Password");
      setPassword();
    }
    else {
      // axios.post("https://api.mytiffi.voidspy.com/api/register", data)
      axios.post("https://api.mytiffi.voidspy.com/api/register", data)
        .then((res) => {
          const data = res.data;
          //console.log(data);
          // const id=data.id;
          if (data[0].status == "success") {

            toast.success('Register Successfully');
          }
          else if (data[0].status == "Fail") {
            toast.error('Email Already Registered');

          }
        })
    }

  }

  function Logindata() {
    const data = {
      'email': email,
      'password': password
    };
    // axios.post("https://api.mytiffi.voidspy.com/api/login", data)
    axios.post("https://api.mytiffi.voidspy.com/api/login", data)
      .then((res) => {
        const data = res.data;
        console.log(data);
        // const id=data.id;
        if (data[0].status == "success") {
          var uid = data[0].userid;
          localStorage.setItem('username', uid);
          localStorage.setItem('uname', email);
          toast.success('Login Successfully');
          window.location.href = '/dashboard';
          //navigate("/Menucard");
        }
        else if (data[0].status == "fail") {
          toast.error('Please Activet Account');

        }
        else if (data[0].status == "invalid") {
          toast.error('Please Enter Valid Inputs');
        }
      })
  }
  return (
    <React.Fragment>
      {addCss('/css/Style.css')}
      {addCss('/css/navbar.css')}
      <div className="container-fluid" style={{ position: 'absolute' }}>
        <div className="row">
          <div className="col-sm-12 px-0">

            <nav className="navbar navbar-expand-lg navbar-light bg-darks ">
              <div className="container">
                <a className="text-white navbar-brand navbarbrandmain" href="/">
                  <img src='/Images/mytiffi white png-01.png' style={{ height: 50, width: 140 }} />
                </a>
                <button className="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent" aria-expanded={!isNavCollapsed ? true : false} aria-label="Toggle navigation" onClick={handleNavCollapse}>
                  <span className="toggler-icon top-bar"></span>
                  <span className="toggler-icon middle-bar"></span>
                  <span className="toggler-icon bottom-bar"></span>
                </button>



                <div className={`${isNavCollapsed ? 'collapse' : ''} offcanvas-body navbarul`}>
                  <ul className="navbar-nav mx-auto">
                    <li className="nav-item">
                      <a className=" active" aria-current="page" href="#">Home</a>
                    </li>



                    <li className="nav-item">
                      <a className="" href="/subscription">Menu</a>
                    </li>
                    <li className="nav-item">
                      <a className="" href="#" data-toggle="modal" data-target="#sub">Specialities</a>
                    </li>


                    <li className="nav-item nav-item-color">
                      <a className="" href="#">About</a>
                    </li>

                    <li className="nav-item">
                      <a className="" href="#">Contact</a>
                    </li>
                    <li className="nav-item btlog">
                      <a className="" href="#" data-toggle="modal" data-target="#exampleModal">Login</a>
                      {/* <button type='button' >Login</button> */}
                    </li>
                    <li className="nav-item text-end">
                      <i className="fa fa-heart-o" style={{ fontSize: "24px", color: '#e20900' }}></i>
                    </li>

                    <li className="nav-item text-end">
                      <i className="fa fa-shopping-cart" style={{ fontSize: "24px", color: '#e20900' }}></i>
                    </li>


                  </ul>
                  {/* <div className="d-flex carti1">


                  </div> */}
                </div>

                <div className=" offcanvas-body navbarul navlap1">
                  <ul className="navbar-nav mx-auto">
                    <li className="nav-item">
                      <a className=" active" aria-current="page" href="/">Home</a>
                    </li>



                    <li className="nav-item">
                      {/* <a className="" href="/subscription">Menu</a> */}
                      <a className="" href="" data-toggle="modal" data-target="#menu">Menu</a>
                    </li>
                    <li className="nav-item">
                      <a className="" href="" data-toggle="modal" data-target="#sub">Specialities</a>
                    </li>


                    <li className="nav-item nav-item-color">
                      <a className="" href="#">About</a>
                    </li>

                    <li className="nav-item">
                      <a className="" href="#">Contact</a>
                    </li>
                    <li className="nav-item btlog">
                      <a className="" href="" data-toggle="modal" data-target="#exampleModal">Login</a>
                      {/* <button type='button' >Login</button> */}
                    </li>
                    <li className="nav-item text-end">
                      <i className="fa fa-heart-o" style={{ fontSize: "24px", color: '#e20900' }}></i>
                    </li>

                    <li className="nav-item text-end">
                      <i className="fa fa-shopping-cart" style={{ fontSize: "24px", color: '#e20900' }}></i>
                    </li>


                  </ul>
                  {/* <div className="d-flex carti1">


                  </div> */}
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>




      <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content" id="modelmainnew">

            <div className="modal-body" id="modelbodynew">

              <div className="container" style={{ border: 'none' }} id="logindiv">
                <div className="row no-gutter my-5" id="div1">
                  <div className="col-md-7 logim1">
                    <img src="/Images/Log.jpeg" alt="" id="div1img1" />
                  </div>
                  <div className="col-md-5 card p-5 " id="div2" >

                    <div className="main">
                      <div className="d-flex justify-content-end">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" >
                          <span aria-hidden="true">&times;</span>
                        </button>

                      </div>
                      <h2 className="text-center text-dark" ><b>Login</b></h2>
                      {/* <p className="text-center" id="firsttext">Enter to continue and explore within your grasp</p> */}
                      <div className="inputWithIcon mt-2" id="inputiconnew">
                        <input type="text" placeholder="Enter Your Email" onChange={email => setEmail(email.target.value)} />
                        <i className='far fa-user-circle' aria-hidden="true" ></i>

                      </div>


                      <div className="inputWithIcon" id="inputiconnew">
                        <input type="password" placeholder="Enter Password" id="passwordlogin" onChange={password => setPassword(password.target.value)} />
                        <i className="fas fa-unlock-alt" aria-hidden="true" ></i>
                        <i className="fa-solid fa-eye" id="eye"></i>
                      </div>



                      <div className="row">

                        <div className="col-sm-6">
                          <a href="">
                            <input type="radio" name="" id="" value="checkedValue" className="custom-control-input" />
                            Remeber Me
                          </a>
                        </div>
                        <div className="col-sm-6">
                          <a href="">
                            Forgot password?
                          </a>
                        </div>




                      </div>

                      <div className="my-4">
                        <input type="button" className="btn btn-danger btn-lg btn-block btnloginnew" id="submitbtn" onClick={() => Logindata()} value="Sign In" />

                      </div>
                      <div className="row mt-3" id="rowsecond">
                        <div className="col-md-6">
                          {/* <button type="button" className="btn btn-light" id="social_btn">
                            <img src="https://img.icons8.com/color/48/null/google-logo.png" />
                            &nbsp; <b>Login With Google</b>
                          </button> */}


                          <LoginSocialGoogle
                            client_id={"1044246419531-g2vm1skpbq0b65qt6qi9hm0e6pkhj3iv.apps.googleusercontent.com"}
                            scope="openid profile email"
                            discoveryDocs="claims_supported"
                            access_type="offline"
                            password='gmail'

                            onResolve={({ provider, data }) => {


                              const udata = {
                                'email': data.email,
                                'mobile': data.mobile,
                                'password': 'gmail'
                              };
                              axios.post("https://api.mytiffi.voidspy.com/api/register", udata)
                                // axios.post("https://api.mytiffi.voidspy.com/api/register", udata)
                                .then((res) => {

                                  const data = res.data;
                                  //console.log(data);
                                  // const id=data.id;
                                  if (data[0].status == "success") {

                                    toast.success('Register Successfully');
                                  }
                                  else if (data[0].status == "Fail") {
                                    toast.error('Email Already Registered');

                                  }
                                })


                              // console.log(provider, data);



                            }}
                            onReject={(err) => {
                              // console.log(err);
                            }}
                          >
                            <GoogleLoginButton style={{ fontSize: "13px", fontWeight: "600" }} />

                          </LoginSocialGoogle>



                        </div>
                        <div className="col-md-6">

                          {!profile ? <LoginSocialFacebook
                            appId="5847458322038523"
                            onResolve={(response) => {
                              console.log(response);
                              setProfile(response.data);
                            }}
                            onReject={(error) => {
                              console.log(error);
                            }}
                            cssClass={{ fontSize: 13, }}
                          >
                            <FacebookLoginButton
                            />
                          </LoginSocialFacebook> : ''}
                          {profile ? <div>
                            <h1>{profile.name}</h1>
                          </div> : ''}

                        </div>


                      </div>

                      <div className="d-flex justify-content-center mt-3">
                        <p>Don't have an account ?<span><a href="" className=" text-danger " data-toggle="modal" data-target="#exampleModal1" data-dismiss="modal"><b>Sign Up</b></a></span></p>
                      </div>
                    </div>
                  </div>


                </div>
              </div>

            </div>

          </div>
        </div>
      </div>

      <div className="modal fade" id="exampleModal1" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content" id="modelmainnew">

            <div className="modal-body" id="modelbodynew">

              <div className="container" style={{ border: 'none' }}>
                <div className="row no-gutter my-5" id="div1">
                  <div className="col-md-7 logim1">
                    <img src="/Images/tiffi1.png" alt="" srcSet="" id="div1img1" />
                  </div>
                  <div className="col-md-5 card p-5 " id="div2" >

                    <div className="main">
                      <div className="d-flex justify-content-end">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" >
                          <span aria-hidden="true">&times;</span>
                        </button>

                      </div>
                      <h2 className="text-center text-dark"><b>Create Account</b></h2>
                      <div className="inputWithIcon mt-5" id="inputiconnew">
                        <input type="text" placeholder="Enter Your Email" onChange={email => setEmail(email.target.value)} />
                        <i className='far fa-user-circle' aria-hidden="true" ></i>

                      </div>
                      <div className="inputWithIcon mobico1" id="inputiconnew">
                        <input type="text" placeholder="Enter Your Phone No" onChange={mobile => setMobile(mobile.target.value)} />
                        <i className="fa fa-mobile fa-lg fa-fw mt-3" aria-hidden="true" ></i>
                      </div>


                      <div className="inputWithIcon" id="inputiconnew">
                        <input type="password" placeholder="Enter Password" id="password" onChange={password => setPassword(password.target.value)} />
                        <i className="fas fa-unlock-alt" aria-hidden="true" ></i>
                        <i className="fa-solid fa-eye" id="eye"></i>
                      </div>





                      <div className="my-4">


                      </div>

                      <input type="button" className="btn btn-lg btn-block btnloginnew" id="submitbtn"
                        onClick={() => Adddata()} value="Sign Up" />

                      <div className="row mt-3" id="rowsecond">
                        {/* <div className="col-md-6">
                  <button type="button" className="btn btn-light" id="social_btn">
                    <img src="https://img.icons8.com/color/48/null/google-logo.png"/>
                    &nbsp; <b>Login With Google</b> &nbsp;
                  </button>
                </div> */}


                        {/* <div className="col-md-6">

                <button type="button" className="btn btn-light" id="social_btn">
                  <img src="https://img.icons8.com/color/48/null/facebook-circled--v1.png" />



                  &nbsp; <b>Login With Google</b> &nbsp;
                </button>
              </div> */}
                      </div>

                      <div className="d-flex justify-content-center mt-3 loguser1">
                        <p>Already have an account ?</p><span><a href="" className="text-danger" data-toggle="modal" data-target="#exampleModal" data-dismiss="modal"><b>Login</b></a></span>
                      </div>
                    </div>
                  </div>


                </div>
              </div>

            </div>

          </div>
        </div>
      </div>



      <div className="modal fade" id="menu" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content" id="modelmainnew">

            <div className="modal-body" id="modelbodynew">

              <div className="container" style={{ border: 'none' }} id="logindiv">
                {/* <Menucard/> */}
                {/* <SubscriptionModal/> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade " id="sub" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content" id="modelmainnew">

            <div className="modal-body" id="modelbodynew">

              <div className="container" style={{ border: 'none' }} id="logindiv">
                <Subscription/>
                
                {/* <SubscriptionModal/> */}
              </div>
            </div>
          </div>
        </div>
      </div>


      <ToastContainer />

      {AddScript('https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js')}
      {AddScript('/js/home.js')}
    </React.Fragment>
  )
}

export default Header
