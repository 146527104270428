import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Google from './Pages/Google';
import Facebook from './Pages/Facebook';
import Home from './Pages/Home';
import Timeline from './Pages/Timeline';
import Checkout from './Pages/Checkout';
import Daily from './Pages/Daily';
import Menucard from './Pages/Menucard';
import Userprofile from './Pages/UserProfile';
// import Subscription from './Pages/Subscription';
import Subscriptiontoday from './Pages/Subscriptiontoday';
import Subscription from './components/Subscription';
import Profile from './components/Profile';
import Dashboard from './components/Dashboard';

function App() {
  return (
    <BrowserRouter>
      <Routes>  
        <Route path="/" element={<Home/>}/>
        <Route path='/subscription' element={<Subscription/>} />
        <Route path='/profile' element={<Profile/>}/>
        <Route path='/dashboard' element={<Dashboard/>}/>
        <Route path='/checkout' element={<Checkout/>} />
        <Route path='/plan' element={<Timeline/>}/>
        {/* <Route path='/Checkout' element={<Checknewout/>}/> */}
        {/* <Route path='/plan' element={<Timeline/>}/>
        <Route path='/Google' element={<Google/>}/>
        <Route path='/Facebook' element={<Facebook/>}/>
        
        <Route path='/Daily' element={<Daily/>}/>
        <Route path='/Menucard' element={<Menucard/>}/>
        <Route path='/Userprofile' element={<Userprofile/>}/>
        <Route path='/Subscription' element={<Subscription/>}/>
        <Route path='/Subscriptiontoday' element={<Subscriptiontoday/>}/> */}

      </Routes>
    </BrowserRouter>
  );
}

export default App;











 
// import React, {useState, useEffect} from 'react';
// import jwt_decode from 'jwt-decode';
// function App() {

//     const [loginUrl, setLoginUrl] = useState(null);
// function handleCallbackResponse(response)
// {
//   // const [user,setUser] = useState({});
//   console.log("Encoded JWT ID Token "+response.credential);
//   var userobject = jwt_decode(response.credential);
//   console.log(userobject);
//   setLoginUrl(userobject);
// }
//     useEffect(() => {
//        google.accounts.id.initialize({
//         client_id:"403366257124-kt3o8nrtob3m8dina3dmfinipho3k1ei.apps.googleusercontent.com",
//         callback:handleCallbackResponse
//        });
       
//        google.accounts.id.renderButton(
//         document.getElementById("SignInDiv"),
//         {
//           them:"outline",size:"large"
//         }
//       )
//     }, []);

   

//     return (
//         // <div>
//         //     {loginUrl != null && (
//         //         <a href={loginUrl}>Google Sign In</a>
//         //     )}
//         // </div>
//         <div className='App'>
//            <div id='SignInDiv'></div>
//            {loginUrl != null && (
//                <a href={loginUrl}>Google Sign In</a>
//             )}
//         </div>
//     );
// }

// export default App;










// import { GoogleLoginButton } from "react-social-login-buttons";
// import { LoginSocialGoogle } from "reactjs-social-login";

// function App() {
//   return (
//     <div>
//       <LoginSocialGoogle
//         client_id={"1044246419531-g2vm1skpbq0b65qt6qi9hm0e6pkhj3iv.apps.googleusercontent.com"}
//         scope="openid profile email"
//         discoveryDocs="claims_supported"
//         access_type="offline"
//         onResolve={({ provider, data }) => {
//           console.log(provider, data);
//         }}
//         onReject={(err) => {
//           console.log(err);
//         }}
//       >
//         <GoogleLoginButton />
//       </LoginSocialGoogle>
//     </div>
//   );
// }

// export default App;









