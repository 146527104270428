import React, { useEffect, useState } from "react"
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin, { Draggable } from '@fullcalendar/interaction'
import CalendarUi from "./CalendarUi"
import axios from "axios"
import { toast } from "react-toastify"
function Subscription() {

    const [isLoading, setLoading] = useState(true);
    const [mtype, setMType] = useState('Veg')
    const [menu, setMenu] = useState([]);
    const [vmenu, setVMenu] = useState([]);
    const [mnutype, setmnutype] = useState('1');

    const [event, setEvent] = useState([])
    const [showCal, setCal] = useState(false);
    const dt = new Date();
    const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];


    function addCss(urlOfTheLibrary) {
        const script = document.createElement('link');
        script.href = urlOfTheLibrary;
        script.rel = "stylesheet";
        script.type = "text/css";
        document.head.appendChild(script);
        // let draggableEl = document.getElementById('menucard');
        // new Draggable(draggableEl);
    }
    function handleEvent(info) {
        const cdt = new Date();
        const cldt = new Date(info.dateStr);
        console.log(cldt > cdt)
        /* if (cldt > cdt) { */


        var ev = event;
        const t = info.draggedEl.innerHTML.split('-')
        // console.log(t);
        const tm = menu.filter((mn) => mn.name == t[0])
        ev.push({ title: info.draggedEl.innerHTML + '-' + mtype, start: new Date(info.dateStr), mtype: mtype, mnutype: mnutype, mnu: tm[0] })
        setEvent(ev)
        /* }
        else{
            toast.warning('Previous dates are not allowed')
        } */
        // console.log(ev);
    }
    function renderEventContent(eventInfo) {
        // console.log("" + eventInfo.event.title.split('-'));
        const t = eventInfo.event.title.split('-')
        const cdt = new Date();
        const cldt = new Date(eventInfo.event.startStr)
        if (cldt > cdt) {
            const tm = event.filter((mn) => mn.mnu.name == t[0])
            console.log(tm[0].mnutype);
            // var t = eventInfo.event.title.split('-')
            return (
                <>
                    {tm[0].mnu.category === '1' && <button class="box">
                        {tm[0].mnutype === "1" ? <i class="fa fa-brightness"></i>
                            : <i class="fa fa fa-moon-o"></i>
                        }
                        <i class="fa-solid fa-battery-full fa-rotate-270" style={{ color: 'green', backgroundColor: 'white', fontSize: 19, padding: 1, borderRadius: 70 + '%', float: 'left', marginLeft: 10 }}></i>
                        <span style={{ color: 'white' }}>1</span>
                    </button>}

                    {tm[0].mnu.category === '2' && <button class="box1">
                        {tm[0].mnutype === "1" ? <i class="fa fa-brightness"></i>
                            : <i class="fa fa fa-moon-o"></i>
                        }
                        <i class="fa-solid fa-battery-full fa-rotate-270" style={{ color: 'red', backgroundColor: 'white', fontSize: 19, padding: 1, borderRadius: 70 + '%', float: 'left', marginLeft: 10 }}></i>
                        <span style={{ color: 'white' }}>1</span>
                    </button>}

                    {tm[0].mnu.category === '3' && <button class="box2">
                        {tm[0].mnutype === "1" ? <i class="fa fa-brightness"></i>
                            : <i class="fa fa fa-moon-o"></i>
                        }
                        <i class="fa-solid fa-battery-full fa-rotate-270" style={{ color: 'orange', backgroundColor: 'white', fontSize: 19, padding: 1, borderRadius: 70 + '%', float: 'left', marginLeft: 10 }}></i>
                        <span style={{ color: 'white' }}>1</span>
                    </button>}
                </>
            )
        }
    }
    function setDraggable() {
        // console.log(mtype);
        let draggableEl = document.getElementById('pills-tabContent');
        new Draggable(draggableEl, {
            itemSelector: '.title',
            eventData: (eventel) => {

                return {
                    title: eventel.innerHTML + '-' + mtype

                }
            }
        });

    }

    function fetchMenu() {
        var mon = dt.getMonth() < 9 ? "0" + (dt.getMonth() + 1) : (dt.getMonth() + 1)
        var dts = dt.getDate() < 10 ? "0" + dt.getDate() : dt.getDate()
        var tdt = dt.getFullYear() + "-" + mon + "-" + dts;
        console.log(tdt);
        // setCal(false)
        axios.get('https://api.mytiffi.voidspy.com/api/today-menu/' + tdt)
            .then((res) => {
                console.log(res.data);
                const data = res.data;

                setMenu(data);
                setVMenu(data.filter((mn) => mn.category == '1'))
                setCal(true)

                //console.log(data);

            })
    }
    console.log(menu);
    console.log(vmenu);
    useEffect(() => {

        setDraggable();
        fetchMenu();
    }, [showCal])

    return (
        <React.Fragment>
            {addCss('/css/sub/style.css')}
            <div class="container-fluid-md bg-white" >
                <div class="row gx-0">
                    <div class="col-md-4 bg-dark">


                        <div id="mySidepanel" class="sidepanel">
                            <a href="javascript:void(0)" class="closebtn" onclick="closeNav()">×</a>
                            <a href="#">Home</a>
                            <a href="#">Menu</a>
                            <a href="#">Specilities</a>
                            <a href="#">About</a>
                            <a href="#">Contact</a>
                            <a href="#">Login</a>

                        </div>

                        <button class="openbtn" onclick="openNav()">☰ <a href="/"><img src="/Images/3.png" height="50px" /></a></button>




                        <hr style={{ border: "1px solid lightgray" }} />

                        {/* <div class="date">
                            <h2 class="ms-5" style={{ fontFamily: "sans-serif", fontSize: "25px" }}>{weekday[dt.getDay()]}</h2>
                            <p class="ms-5" style={{ marginTop: "-10px", fontWeight: "100" }}>{dt.getDate()}th {month[dt.getMonth()]} {dt.getFullYear()}<button type="button" class="fas fa-angle-left bg-dark l"></button><button
                                type="button" class="fas fa-angle-right bg-dark r"></button>
                            </p>

                        </div> 

                        <hr style={{ width: 90 + '%', marginLeft: 20 + 'px', border: "1px solid lightgray" }} />*/}
                        {mnutype == "1" ? (
                            <>
                                <button class="btn1active me-md-2" type="button" name="btn" onClick={() => {
                                    setmnutype("1")
                                    setVMenu(menu.filter((mn) => mn.meal_type == '1' && mn.category == mtype))
                                }}><i class="fa fa-brightness"></i><font style={{ marginLeft: "10px", fontSize: "15px" }}>Lunch</font></button>
                                <button class="btn2 active" type="button" name="btn" onClick={() => {
                                    setmnutype("2")
                                    setVMenu(menu.filter((mn) => mn.meal_type == '2' && mn.category == mtype))
                                }}><i class="fa fa-moon-o"></i><font style={{ marginLeft: "0px", fontSize: "15px" }}>Dinner</font></button>
                            </>
                        ) :
                            (
                                <>
                                    <button class="btn1 me-md-2" type="button" name="btn" onClick={() => {
                                        setmnutype("1")
                                        setVMenu(menu.filter((mn) => mn.meal_type == '1' && mn.category == mtype))
                                    }}><i class="fa fa-brightness"></i><font style={{ marginLeft: "10px", fontSize: "15px" }}>Lunch</font></button>
                                    <button class="btn2active active" type="button" name="btn" onClick={() => {
                                        setmnutype("2")
                                        setVMenu(menu.filter((mn) => mn.meal_type == '2' && mn.category == mtype))
                                    }}><i class="fa fa-moon-o"></i><font style={{ paddingLeft: "0px", fontSize: "15px" }}>Dinner</font></button>
                                </>
                            )}

                        <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home"
                                    type="button" role="tab" aria-controls="pills-home" aria-selected="true" onClick={() => {
                                        setMType('1');
                                        setVMenu(menu.filter((mn) => mn.category == '1'))
                                    }}>Veg</button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile"
                                    type="button" role="tab" aria-controls="pills-profile" aria-selected="false" onClick={() => {
                                        setMType('2')
                                        setVMenu(menu.filter((mn) => mn.category == '2'))
                                    }}>Nonveg</button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact"
                                    type="button" role="tab" aria-controls="pills-contact" aria-selected="false" onClick={() => {
                                        setMType('3')
                                        setVMenu(menu.filter((mn) => mn.category == '3'))
                                        console.log(mtype);
                                    }}>Egg</button>
                            </li>
                        </ul>
                        <div class="tab-content" id="pills-tabContent">
                            <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">

                                {
                                    vmenu.map((obj) => {
                                        return (
                                            <div class="menucard" id="menucard">
                                                <div class="buttons active text-light ms-3">
                                                    <input type="radio" checked id="a25" name="check-substitution-2" />
                                                    <label class="btn btn-active text-light" for="a25">Regular</label>
                                                </div>
                                                <div class="buttons ">
                                                    <input type="radio" id="a50" name="check-substitution-2" />
                                                    <label class="btn btn-default text-light " for="a50">Half</label>
                                                </div>
                                                <h4 class="title fw-bold" style={{ color: 'darkgreen' }}>{obj.cname}</h4>
                                                <p class="" style={{ color: 'darkgreen' }}>
                                                    {
                                                        JSON.parse(obj.menu_card).map((mnu) => {
                                                            return (
                                                                <>
                                                                    {mnu.meal.name} {mnu.qty},<br />
                                                                </>
                                                            );
                                                        })
                                                    }
                                                </p>
                                                <p class="messagecls">* Drag & Drop this meal box to calendar</p>

                                                <button type="button" class="btn1 fw-bold " style={{ position: 'absolute', background: 'darkgreen' }}>
                                                    <i class="fa fa-rupee "
                                                        style={{ background: '#fff', borderRadius: 50 + '%', color: 'darkgreen', padding: "5px 10px" }}></i>&nbsp;&nbsp;{obj.price}
                                                </button>



                                                <img src="/Images/m.png" height="300px" width="320px" class="img1" />


                                            </div>
                                        )
                                    })}
                            </div>
                            <div class="tab-pane fade text-light" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                {
                                    vmenu.map((obj) => {
                                        return (
                                            <div class="menucard" id="menucard">
                                                <div class="buttons active text-light ms-3">
                                                    <input type="radio" checked id="a25" name="check-substitution-3" />
                                                    <label class="title btn btn-default text-light" for="a25">Regular</label>

                                                </div>
                                                <div class="buttons">
                                                    <input type="radio" id="a50" name="check-substitution-3" />
                                                    <label class="btn btn-default text-light " for="a50">Half</label>
                                                </div>
                                                <h4 class="title text-danger fw-bold">{obj.cname}</h4>
                                                <p class="text-danger">{
                                                    JSON.parse(obj.menu_card).map((mnu) => {
                                                        return (
                                                            <>
                                                                {mnu.meal.name} {mnu.qty},<br />
                                                            </>
                                                        );
                                                    })
                                                }</p>
                                                <p class="messageclst" >* Drag & Drop this meal box to calendar</p>
                                                <button type="button" class="btn1 fw-bold  bg-danger" style={{ position: 'absolute' }}>
                                                    <i class="fa fa-rupee "
                                                        style={{ background: '#fff', borderRadius: 50 + '%', color: 'red', padding: "5px 10px" }}></i>&nbsp;&nbsp;{obj.price}
                                                </button>


                                                <img src="/Images/chicken.png" height="300px" width="320px" class="img1" />

                                            </div>)
                                    })}

                            </div>
                            <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                                {
                                    vmenu.map((obj) => {
                                        return (

                                            <div class="menucard" id="menucard">
                                                <div class="buttons active text-light ms-3 ">
                                                    <input type="radio" checked id="a25" name="check-substitution-4" />
                                                    <label class="title btn btn-default text-light" for="a25">Regular</label>
                                                </div>
                                                <div class="buttons">
                                                    <input type="radio" class="active" id="a50" name="check-substitution-4" />
                                                    <label class="btn btn-default text-light " for="a50">Half</label>
                                                </div>

                                                <h4 class="title fw-bold" style={{ color: 'orange' }}>{obj.cname}</h4>
                                                <p class="" style={{ color: 'orange' }}>{
                                                    JSON.parse(obj.menu_card).map((mnu) => {
                                                        return (
                                                            <>
                                                                {mnu.meal.name} {mnu.qty},<br />
                                                            </>
                                                        );
                                                    })
                                                }</p>
                                                <p class="messageclsy">* Drag & Drop this meal box to calendar</p>
                                                <button type="button" class="btn1 fw-bold " style={{ position: 'absolute', background: 'orange' }}>
                                                    <i class="fa fa-rupee "
                                                        style={{ background: '#fff', borderRadius: 50 + '%', color: 'orange', padding: "5px 10px", marginLeft: "-9px" }}></i>&nbsp;&nbsp;{obj.price}
                                                </button>


                                                <img src="/Images/egg.png" height="300px" width="320px" class="img1" />

                                            </div>)
                                    })}
                            </div>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="line1">

                        </div>

                        <div class="container r">
                            <div class="d-flex justify-content-evenly mb-4">
                                <div class="rounds">
                                    <input type="checkbox" checked id="checkbox" />
                                    <label for="checkbox"></label>
                                </div>
                                <div class="rounds">
                                    <input type="checkbox" id="checkbox" />
                                    <label for="checkbox"></label>
                                </div>
                                <div class="rounds">
                                    <input type="checkbox" id="checkbox" />
                                    <label for="checkbox"></label>
                                </div>
                            </div>
                        </div>
                        {/* <i class="fa fa-shopping-cart" aria-hidden="true"
                            onClick={() => {
                                localStorage.setItem('cart', JSON.stringify(event))
                                window.location.href = "/checkout"
                            }}
                            style={{ float: 'right', marginRight: 30 + 'px', color: 'red', fontSize: 20, marginTop: -37 }}>


                        </i> */}
                        <hr style={{ marginTop: -3, border: "1px solid grey" }} />

                        <div class="container-fluid">
                            {/* <CalendarUi mtype={mtype} event={event} handleEvent={handleEvent} /> */}
                            <div style={{ height: 680 }}>
                                {showCal && <FullCalendar
                                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                    initialView='dayGridMonth'
                                    headerToolbar={{
                                        left: 'timeGridDay,timeGridWeek,dayGridMonth',
                                        center: 'title',
                                        right: 'prev,next today'
                                    }}
                                    weekends={true}
                                    initialEvents={event}
                                    droppable={true}
                                    drop={(info) => {
                                        handleEvent(info);
                                    }}
                                    eventContent={renderEventContent}
                                    eventsSet={(events) => {
                                        setEvent(events)
                                    }}
                                />}
                            </div>
                            <div>
                                <span><button class="addtocart" onClick={() => {
                                    localStorage.setItem('cart', JSON.stringify(event))
                                    window.location.href = "/checkout"
                                }}>Add To Cart <i class="fa-solid fa-arrow-right"></i></button><button class="cancelbtn" data-dismiss="modal">Cancel</button></span>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </React.Fragment>
    )
}
export default Subscription