import Checkoutcss from './Checkoutcss'
import Checkoutjs from './Checkoutjs'
import React, { useEffect, useState } from 'react'
import axios from 'axios'

function Checkout() {
  const [cart, setCart] = useState([]);
  const [name, setName] = useState('');
  const uid = localStorage.getItem('username');
  //console.log(uid);

  function handleSubmit() {
    // const content = {
    //   name: first,
    //   qty: menuf,
    //   names: seconde,
    //   qtys: menus,
    //   namet: third,
    //   qtyt: menut
    // }
    // const demo = JSON.stringify(content);
    // //console.log(content);
    // const data = {
    //   'name': name,
    //   'description': 'description',
    //   'content': demo,
    //   'price': '50',
    //   'uid': uid
    // }
    // //console.log(data);

    // axios.post("http://localhost:8000/api/usermeal", data)
    //   .then((resp) => {
    //     const data = resp.data;
    //     console.log(resp);
    //     if (data[0].status === "success") {
    //       console.log("Data Added");
    //       alert('Data Added!!!');
    //       window.location.href = '/Checkout';
    //     }
    //     else {
    //       console.log("Error");
    //     }
    //   });
  }

  useEffect(() => {
    setCart(JSON.parse(localStorage.getItem("cart")));
  }, [])

  if (cart.length > 0) {
    return (
      <div>
        <Checkoutcss />
        <Checkoutjs />






        <div class="container-fluid">
          <div class="row mt-5">
            <div class="line1">

            </div>
            <div class="container r">
              <div class="d-flex justify-content-evenly mb-4">
                <div class="round ">
                  <input type="checkbox" checked id="checkbox" />
                  <label for="checkbox"></label>
                </div>
                <div class="round">
                  <input type="checkbox" checked id="checkbox" />
                  <label for="checkbox"></label>
                </div>
                <div class="round ">
                  <input type="checkbox" id="checkbox" />
                  <label for="checkbox"></label>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="line">
                {/* <ul>
                  <li class="">
                    <li><div class="radio-buttons">
                      <label class="custom-radio">
                        <input type="radio" name="radio1 name" value='Veg' defaultChecked={cart[0].mtype == "veg"} />
                        <span class="radio-btn"><i class="fa fa-check"></i>
                          <div class="hobbies-icon">
                            <div class="veg1">
                              <i class="fa-solid fa-broccoli"></i>
                            </div>
                            <h3>Veg</h3>
                          </div>
                        </span>
                      </label>
                      <label class="custom-radio">
                        <input type="radio" name="radio1 name" value='Non Veg' defaultChecked={cart[0].mtype == "non"} />
                        <span class="radio-btn nonvegbtn"><i class="fa fa-check"></i>
                          <div class="hobbies-icon">
                            <div class="Nonveg">
                              <i class="fa-solid fa-turkey"></i>
                            </div>
                            <h3>Nonveg</h3>
                          </div>
                        </span>
                      </label>
                      <label class="custom-radio">
                        <input type="radio" name="radio1 name" value='Egg' defaultChecked={cart[0].mtype == "egg"} />
                        <span class="radio-btn egg"><i class="fa fa-check"></i>
                          <div class="hobbies-icon">
                            <div class="Egg">
                              <i class="fa-solid fa-egg"></i>
                            </div>
                            <h3>Egg</h3>
                          </div>
                        </span>
                      </label>


                    </div>
                    </li>
                  </li>
                </ul> */}


                <div class="row mt-2">
                  <div class="container px-5">


                    <div class="clearfix">
                      <span class="float-left"><h4>Choose  Meal Quantity</h4></span>
                      {/* <span class="float-right">  <ul class="nav nav-pills" role="tablist">
                      <li class="nav-item">
                        <a class="nav-link active" data-bs-toggle="pill" href="#home">Regular</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" data-bs-toggle="pill" href="#menu1">Half</a>
                      </li>

                    </ul></span> */}
                    </div>
                  </div>




                  <div class="tab-content">
                    <div id="home" class="container tab-pane active"><br />
                      <div class="card">
                        <div class="card-body">



                          <div class="">
                            <div class="row">
                              <div class="col-md-12">

                                <ul>
                                  <div class="clearfix my-2">
                                    <span class="float-left col-md-8">
                                      <h5> <b>{cart[0].mnu.name}</b></h5>
                                    </span>
                                    <span class="float-right col-md-4">

                                      <div class="input-group" id="checkout_inc">
                                        <input type="button" value="+" class="button-plus" data-field="quantity"
                                          id="checkout_inc1" />
                                        <input type="number" step="1" max="" value="1" id="checkout_inc2" name="quantity" class="quantity-field" />
                                        <input type="button" value="-" class="button-minus" data-field="quantity"
                                          id="checkout_inc1" />
                                      </div>

                                    </span>
                                  </div>
                                  <hr id="checkout_hr1" />
                                  <li>
                                    <div class="clearfix my-4 mb-5">
                                      <span class="float-left col-md-8">
                                        <h6><b>Meal Include</b></h6>
                                      </span>
                                      <span class="float-right col-md-4"> <h6><b> Extra</b></h6>
                                      </span>
                                    </div>

                                    {/* <div class="clearfix">
                                    <span class="float-left col-md-7">
                                      <b>Chapati 3</b>
                                      
                                    </span>
                                    <span class="float-right col-md-5">
                                      <div class="input-group" id="checkout_meal_item">
                                        <input type="button" value="+" class="button-plus" data-field="quantity"
                                          id="checkout_inc1" />
                                        <input type="number" step="1" max="" value="1" id="checkout_inc2" name="quantity content" class="quantity-field"
                                          onChange={(menuf) => { setMenuf(menuf.target.value) }} />

                                        <input type="button" value="-" class="button-minus" data-field="quantity"
                                          id="checkout_inc1" />
                                      </div>
                                    </span>
                                  </div> */}
                                  </li>
                                  {
                                    JSON.parse(cart[0].mnu.menu_card).map((obj) => {
                                      return (
                                        <li class="mt-3">
                                          <div class="clearfix">
                                            <span class="float-left col-md-7">
                                              <b>{obj.meal.name}</b>

                                            </span>
                                            <span class="float-right col-md-5">
                                              <div class="input-group" id="checkout_inc3">
                                                <input type="button" value="+" class="button-plus" data-field="quantity"
                                                  id="checkout_inc1" name='content'
                                                />

                                                <input type="number" step="1" max="" value="1" id="checkout_inc2" name="quantity content"
                                                  class="quantity-field" />

                                                <input type="button" value="-" class="button-minus" data-field="quantity"
                                                  id="checkout_inc1" name='content'
                                                />

                                              </div>
                                            </span>
                                          </div>
                                        </li>
                                      )
                                    })
                                  }
                                  {/* <li class="mt-3">
                                  <div class="clearfix">
                                    <span class="float-left col-md-7">
                                      <b> Veg Bhaji Gravy</b>

                                    </span>
                                    <span class="float-right col-md-5">
                                      <div class="input-group" id="checkout_inc3">
                                        <input type="button" value="+" class="button-plus" data-field="quantity"
                                          id="checkout_inc1"

                                        />
                                        <input type="number" step="1" max="" value="1" id="checkout_inc2" name="quantity"
                                          class="quantity-field" onChange={(menut) => { setMenut(menut.target.value) }} />

                                        <input type="button" value="-" class="button-minus" data-field="quantity"
                                          id="checkout_inc1" />
                                      </div>
                                    </span>
                                  </div>
                                </li> */}



                                </ul>
                                <button class="btn btn-success" onClick={() => handleSubmit()}>Submit</button>
                              </div>

                            </div>
                          </div>
                        </div>

                      </div>




                    </div>
                    <div id="menu1" class="container tab-pane fade"><br />
                      <div class="card">
                        <div class="card-body">



                          <div class="">
                            <div class="row">
                              <div class="col-md-12">

                                <ul>
                                  <div class="clearfix my-2">
                                    <span class="float-left col-md-8">
                                      <h5> <b>Regular Meal Half</b></h5>
                                    </span>
                                    <span class="float-right col-md-4">

                                      <div class="input-group" id="checkout_inc4">
                                        <input type="button" value="+" class="button-plus" data-field="quantity"
                                          id="checkout_inc1" />
                                        <input type="number" step="1" max="" value="1" id="checkout_inc2" name="quantity" class="quantity-field" />

                                        <input type="button" value="-" class="button-minus" data-field="quantity"
                                          id="checkout_inc1" />
                                      </div>

                                    </span>
                                  </div>
                                  <hr id="checkout_hr1" />
                                  <li>
                                    <div class="clearfix my-4 mb-5">
                                      <span class="float-left col-md-8">
                                        <h6><b>Meal Include</b></h6>
                                      </span>
                                      <span class="float-right col-md-4"> <h6><b> Extra</b></h6>
                                      </span>
                                    </div>

                                    <div class="clearfix">
                                      <span class="float-left col-md-7">
                                        <b>Chapati 3</b>
                                      </span>
                                      <span class="float-right col-md-5">
                                        <div class="input-group" id="checkout_inc3">
                                          <input type="button" value="+" class="button-plus" data-field="quantity"
                                            id="checkout_inc1" />
                                          <input type="number" step="1" max="" value="1" id="checkout_inc2" name="quantity" class="quantity-field" />

                                          <input type="button" value="-" class="button-minus" data-field="quantity"
                                            id="checkout_inc1" />
                                        </div>
                                      </span>
                                    </div>
                                  </li>
                                  <li class="mt-3">
                                    <div class="clearfix">
                                      <span class="float-left col-md-7">
                                        <b>Veg Bhaji Dry</b>
                                      </span>
                                      <span class="float-right col-md-5">
                                        <div class="input-group" id="checkout_inc3">
                                          <input type="button" value="+" class="button-plus" data-field="quantity"
                                            id="checkout_inc1" />
                                          <input type="number" step="1" max="" value="1" id="checkout_inc2" name="quantity" class="quantity-field" />

                                          <input type="button" value="-" class="button-minus" data-field="quantity"
                                            id="checkout_inc1" />
                                        </div>
                                      </span>
                                    </div>
                                  </li>



                                  <li class="mt-3">
                                    <div class="clearfix">
                                      <span class="float-left col-md-7">
                                        <b> Veg Bhaji Gravy</b>
                                      </span>
                                      <span class="float-right col-md-5">
                                        <div class="input-group" id="checkout_inc3">
                                          <input type="button" value="+" class="button-plus" data-field="quantity"
                                            id="checkout_inc1" />
                                          <input type="number" step="1" max="" value="1" id="checkout_inc2" name="quantity" class="quantity-field" />

                                          <input type="button" value="-" class="button-minus" data-field="quantity"
                                            id="checkout_inc1" />
                                        </div>
                                      </span>
                                    </div>
                                  </li>



                                </ul>

                              </div>

                            </div>
                          </div>

                        </div>

                      </div>

                    </div>

                  </div>

                </div>
              </div>




            </div>
            <div class="col-md-6">
              <h5>Order Summary</h5>
              <table class="table table-borderless">
                <thead>
                  <tr>
                    <th scope="col">Meal / Extra</th>
                    <th scope="col">Qty</th>
                    <th scope="col">Rate</th>

                  </tr>

                </thead>

                <tbody>

                  <tr>
                    <td><b>Extra Chapati</b></td>
                    <td><b>5</b></td>
                    <td><b>100</b></td>
                  </tr>
                  <tr>
                    <td><b>Regular Non Veg  Meal</b></td>
                    <td><b>2</b></td>
                    <td><b>350</b></td>
                  </tr>
                  <tr>
                    <td><b>Regular Veg Meal</b></td>
                    <td><b>3</b></td>
                    <td><b>300</b></td>
                  </tr>


                </tbody>

              </table>
              <hr id="checkout_hr2" />
              <div class="clearfix container col-md-12">
                <span class="float-left col-md-10"><h4>Total:</h4></span>
                <span class="float-right col-md-2"> <b>699</b>
                </span>
              </div>

            </div>
          </div>
        </div>


      </div>





    )
  }
}

export default Checkout