import React, { useState, useEffect } from 'react'
import Header from './Header';
import Loader from '../components/Loader';
import axios from 'axios';
const Home = () => {
    const [isLoading, setLoading] = useState(true);
    const [vegdata, setVegdata] = useState([]);
    const [eggdata, setEggdata] = useState([]);
    const [nonvegdata, setNonvegdata] = useState([]);
    const dt = new Date();
   /*  axios.get("https://api.mytiffi.voidspy.com/api/nonvegcount")
        .then((res) => {
            const data = res.data;
            console.log(data);
            setNonvegdata(data[0].name);
        });
    axios.get("https://api.mytiffi.voidspy.com/api/vegcount")
        .then((res) => {
            const data = res.data;
            setVegdata(data[0].name);
        });
    axios.get("https://api.mytiffi.voidspy.com/api/eggcount")
        .then((res) => {
            const data = res.data;

            setEggdata(data[0].name);
        }); */

    function fetchMenu() {
        var mon = dt.getMonth() < 9 ? "0" + (dt.getMonth() + 1) : (dt.getMonth() + 1)
        var dts = dt.getDate() < 10 ? "0" + dt.getDate() : dt.getDate()
        var tdt = dt.getFullYear() + "-" + mon + "-" + dts;
        console.log(tdt);
        axios.get('https://api.mytiffi.voidspy.com/api/today-menu/' + tdt)
            .then((res) => {
                setLoading(false)
                console.log(res.data);
                const data = res.data;

                // setMenu(data);
                setVegdata(data.filter((mn) => mn.category == '1'))
                setNonvegdata(data.filter((mn) => mn.category == '2'))
                setEggdata(data.filter((mn) => mn.category == '3'))

                //console.log(data);

            })
    }

    useEffect(() => {
        fetchMenu();
        setLoading(false)

    }, [])

    return (
        <React.Fragment>

            {isLoading ? <Loader /> : (<React.Fragment>
                <Header />
                <section class="socialm">
                    <i class="fa fa-facebook" style={{ fontSize: "24px", padding: "10px" }}></i>
                    <i class="fa fa-instagram" style={{ fontSize: "24px", padding: "10px" }}></i>
                    <i class="fa fa-whatsapp" style={{ fontSize: "24px", padding: "10px" }}></i>
                </section>
                <section class="Home" style={{ background: '#0d0d0d' }}>
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-lg-7 txtbg">
                                <div class="cal d-flex">
                                    <i class="fa fa-phone" aria-hidden="true"></i>
                                    <h3>882 204 2719</h3>
                                </div>
                                <h1>Customised Online Tiffin Service</h1>
                                <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Tempore delectus distinctio dolor dolores non maiores sequi consequatur labore recusandae, incidunt excepturi quo at eaque molestias saepe. Aspernatur excepturi saepe ipsam.</p>
                                <a href='/plan'><button class="button1 button4">Order Now <i class="fa fa-caret-right"></i></button></a>
                                <a href='' data-toggle="modal" data-target="#sub"><button class="button button3">Customise Your Plan <i class="fa fa-caret-right"></i></button></a>
                                <div class="labels d-flex">
                                    <div class="label1">
                                        <div class="lab1">
                                            <p style={{ marginTop: "6px", fontFamily: "Arial", fontWeight: "bold", fontSize: "13px" }}>75/-</p>
                                        </div>
                                        {/* <i class="fa-solid fa-broccoli"></i> */}
                                        <img src="/Images/icons8-broccoli-53.png" class="imgs" />
                                        <h5>Veg</h5>
                                    </div>
                                    <div class="label1">
                                        <div class="lab1">
                                            <p style={{ marginTop: "6px", fontFamily: "Arial", fontWeight: "bold", fontSize: "13px" }}>145/-</p>
                                        </div>
                                        <img src="/Images/icons8-chicken-64 (1).png" class="imgs" />
                                        <h5>Nonveg</h5>
                                    </div>
                                    <div class="label1">
                                        <div class="lab1">
                                            <p style={{ marginTop: "6px", fontFamily: "Arial", fontWeight: "bold", fontSize: "13px" }}> 110/-</p>
                                        </div>
                                        <img src="/Images/icons8-eggs-100.png" class="imgs" />
                                        <h5>Egg</h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-5 slide1">

                                <h1 class="ts1">Today's <span>Special</span> </h1>
                                <div class="col-lg-6 col-md-12 thali">
                                    <div class="holderCircle">
                                        <div class="round"></div>
                                        <div class="dotCircle">
                                            <span class="itemDot veg active itemDot1" data-tab="1">
                                                <img src="/Images/icons8-broccoli-53.png" class="imgs" />
                                                <span class="forActive"></span>


                                                {vegdata.length >0 && <p>{JSON.parse(vegdata[0].menu_card).map((obj) => {
                                                    return (
                                                        <>
                                                            {obj.meal.name + "- " + obj.qty} <br />
                                                        </>
                                                    )
                                                })}</p>}

                                                {/* <p>{vegdata}</p> */}
                                            </span>

                                            <span class="itemDot egg itemDot2" data-tab="2">
                                                <img src="/Images/icons8-eggs-100.png" class="imgs" />
                                                <span class="forActive"></span>
                                                {/* <p>chicken(dry)<br />chicken(gravy)</p> */}
                                                {nonvegdata.length >0 && <p>{JSON.parse(nonvegdata[0].menu_card).map((obj) => {
                                                    return (
                                                        <>
                                                            {obj.meal.name + "- " + obj.qty} <br />
                                                        </>
                                                    )
                                                })}</p>}
                                            </span>

                                            <span class="itemDot itemDot3 chicken" data-tab="3">
                                                <img src="/Images/icons8-chicken-64 (1).png" class="imgs" />
                                                <span class="forActive"></span>
                                                {/* <p>chicken(dry)<br />chicken(gravy)</p> */}
                                                {eggdata.length >0 && <p>{JSON.parse(eggdata[0].menu_card).map((obj)=>{
                                                    return (
                                                        <>
                                                            {obj.meal.name +"- "+obj.qty} <br/>
                                                        </>
                                                    )
                                                })}</p>}

                                            </span>

                                        </div>
                                        <div class="contentCircle">
                                            <div class="CirItem title-box active CirItem1">
                                                <img class="im1" src="/Images/thali1.png" alt="" srcset="" />
                                            </div>

                                            <div class="CirItem title-box CirItem2">
                                                <img class="im2" src="/Images/thali2.png" alt="" srcset="" />
                                            </div>

                                            <div class="CirItem title-box CirItem3">
                                                <img class="im3" src="/Images/thali3.png" alt="" srcset="" />

                                            </div>




                                        </div>
                                    </div>
                                </div>

                                <a href="#" style={{ textAlign: 'center', color: 'black', marginTop: -80, fontWeight: 700 }}>
                                    <img src='/Images/icons8-left-arrow-24 (1).png' /><br />
                                    Prev</a>

                                <a href="#" style={{ textAlign: 'center', color: 'black', marginTop: -80, fontWeight: 700 }}>
                                    <img src='/Images/icons8-right-arrow-24 (1).png' /><br />
                                    Next</a>

                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>)}



        </React.Fragment>
    )
}

export default Home
