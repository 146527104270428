import React from 'react'

const Checkoutjs = () => {
  function AddScript(path)
  {
    const script = document.createElement('script')
    script.src = path;
    script.async = true;
    document.body.appendChild(script);
  }
  return (
    <React.Fragment>
      
      
      {AddScript('https://cdn.jsdelivr.net/npm/jquery@3.6.3/dist/jquery.slim.min.js')}
      {AddScript('https://cdn.jsdelivr.net/npm/popper.js@1.16.1/dist/umd/popper.min.js')}
      {AddScript('https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/js/bootstrap.bundle.min.js')}

      {AddScript('https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.bundle.min.js')}
      {AddScript('https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/js/bootstrap.bundle.min.js')}


      {AddScript('https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js')}
      {AddScript('https://cdn.jsdelivr.net/npm/@popperjs/core@2.11.6/dist/umd/popper.min.js')}
      {AddScript('https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha1/dist/js/bootstrap.min.js')}
      {AddScript(' https://code.jquery.com/jquery-3.5.1.js')}
      {AddScript('/js/checkoutscript.js')}
      
     
  

    </React.Fragment>
  )
}

export default Checkoutjs