import React from 'react'

const Checkoutcss = () => {
    function AddCC(urlOfTheLibrary) {
        const script = document.createElement('link');
        script.href = urlOfTheLibrary;
        script.rel ="stylesheet";
        script.type="text/css";
        document.head.appendChild(script);
      }
  return (
    <React.Fragment>
      {AddCC('https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/css/bootstrap.min.css')}
      {AddCC('https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css')}

      {AddCC('/css/sub/style.css')}
      {AddCC('/css/menucard.css')}

  

    </React.Fragment>
  )
}

export default Checkoutcss